import React, { Component } from 'react';
import GoogleMapReact from 'google-map-react';
import logo from '../images/A.svg';

const AlegriaMapLogo = () => <img src={logo} style={{ width: '20px', height: 'auto' }} alt="Alegria logo" />

class SimpleMap extends Component {
  static defaultProps = {
    center: {
      lat: 51.172589,
      lng: 4.446864
    },
    zoom: 14
  };

  render() {
    return (
      // Important! Always set the container height explicitly
      <div style={{ height: '40vh'}}>
        <GoogleMapReact
          bootstrapURLKeys={{ key: 'AIzaSyA6DFuO6HH-7kYJSt3msuSc02fb_DtbN0E' }}
          defaultCenter={this.props.center}
          defaultZoom={this.props.zoom}
        >
          <AlegriaMapLogo
            lat={51.172589}
            lng={4.446864}
          />
        </GoogleMapReact>
      </div>
    );
  }
}

export default SimpleMap;
